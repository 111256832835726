import React, { ElementRef, ReactNode, useEffect, useRef, useState } from "react";
import "./guideline-compliance.scss";
import { FailureIcon, SuccessIcon } from "../../assets/icons";
import { Button, Checkbox } from "antd";
import { DownCircleOutlined, LikeOutlined, UpCircleOutlined } from "@ant-design/icons";
import {
  AdjudicationSummaryItem,
  GuidelineSource,
  patientSummaryService,
  priorAuthService,
  uploadFileFolderService,
} from "../../api";
import { Loading } from "../../components";
import { DiagnosisProps, ProcedureProps } from "../patient-summary";
import { ReactTyped } from "react-typed";
import { CHECKBOX_DATA, GUIDELINES_CHECKBOXES, openNotificationWithIcon } from "../../utils";
import AskAuto from "../../components/ask-auto/AskAuto";
import { guidelineCompliance } from "../case-details-container/constants";
import { SourcePopup } from "../modals/source-popup/SourcePopup";

type Props = {
  enableNextTab: (selectedCheckboxes: string[]) => void;
  procedureCode?: ProcedureProps;
  diagnosisCode?: DiagnosisProps;
  projectId: number;
  documentUUID: string;
  fileName: string;
};

type IOffset = {
  begin: number;
  end: number;
};

type IPages = {
  pageNo: number;
  offsets: IOffset[];
};

type ISources = {
  category: string;
  pages: IPages[];
  documentUUID: string;
  guidelines?: boolean;
};

export const GuidelineCompliance = ({
  enableNextTab,
  procedureCode,
  diagnosisCode,
  projectId,
  documentUUID,
  fileName,
}: Props) => {
  const [chatSource, setChatSource] = useState({} as ISources);
  const [fileUrl, setFileUrl] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isComplianceCollapsed, setIsComplianceCollapsed] = useState(false);
  const [showExpandCollapse, setShowExpandCollapse] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<string[]>([]);
  const [guidelineComplianceData, setGuidelineComplianceData] = useState(
    [] as AdjudicationSummaryItem[]
  );
  const [showNewCompliance, setShowNewCompliance] = useState(false);
  const [newComplianceData, setNewComplianceData] = useState([] as AdjudicationSummaryItem[]);
  const [clinicalGuidelineSources, setClinicalGuidelineSources] = useState([] as GuidelineSource[]);
  const [newPatientSummaryData, setNewPatientSummaryData] = useState("");
  const [isFaxSummaryCollapsed, setIsFaxSummaryCollapsed] = useState(false);
  const [claimsHistory, setClaimsHistory] = useState("");
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const checkboxesRef = useRef<ElementRef<"div">>(null);
  const rerunRef = useRef<ElementRef<"div">>(null);

  const generateGuidelineCompliance = async () => {
    try {
      const { data } = await priorAuthService.generateGuidelineCompliance(
        documentUUID,
        diagnosisCode,
        procedureCode
      );
      if (fileName === "Enrolee Info.pdf") {
        setGuidelineComplianceData(guidelineCompliance);
      } else {
        setGuidelineComplianceData(data.data.adjudication_summary || data.data.message);
        setClinicalGuidelineSources(data.data.guideline_sources || []);
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    generateGuidelineCompliance();
  }, []);

  const handleSourceClick = async (source: GuidelineSource) => {
    setChatSource(source);
    try {
      const { data } = await uploadFileFolderService.getSignedURLForFullSource(source.documentUUID);
      setFileUrl(data.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const generateNewPatientSummary = async (checkboxText: string) => {
    try {
      setIsSummaryLoading(true);
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        diagnosisCode,
        procedureCode,
        checkboxText.toUpperCase().replace(/\s/g, "_")
      );
      setNewPatientSummaryData(data.data.summary || data.data.message);
      setClaimsHistory(data.data?.claims_history);
    } catch (err: any) {
      console.log(err.response.data.message);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const handleCheckboxChange = (checked: boolean, checkboxText: string) => {
    setClaimsHistory("");
    const newSelectedCheckboxes = checked ? [checkboxText] : [];
    setSelectedCheckboxes(newSelectedCheckboxes);
    if (checked) {
      generateNewPatientSummary(checkboxText);
    }

    if (checkboxesRef.current) {
      const claimHistoryElement = checkboxesRef.current.querySelector("#guidelines-checkboxes-group");
      if (claimHistoryElement) {
        claimHistoryElement.scrollIntoView({ behavior: "smooth" });
      }
    }

    setIsCheckboxChecked(checked);
    setIsComplianceCollapsed(true);
    setShowExpandCollapse(true);
  };

  const handleRerunCompliance = async () => {
    try {
      if (isCheckboxChecked) {
        const { data } = await priorAuthService.generateGuidelineCompliance(
          documentUUID,
          diagnosisCode,
          procedureCode,
          selectedCheckboxes[0].toUpperCase().replace(/\s/g, "_")
        );
        setNewComplianceData(data.data.adjudication_summary || data.data.message);
        setShowNewCompliance(true);
        setIsFaxSummaryCollapsed(true);
      }
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    if (newComplianceData.length != 0 && rerunRef.current) {
      const rerunElement = rerunRef.current.querySelector("#rerun-compliance-reference");
      if (rerunElement) {
        rerunElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [newComplianceData]);

  
  const handleGenerateAuthorization = () => {
    enableNextTab(selectedCheckboxes);
  };

  const renderGuidelinesCompilance = (): ReactNode => {
    return (
      <div className="pr m-b">
        <div ref={checkboxesRef} className="flex jcsb guidelines-guidelines">
          <div>GUIDELINE</div>
          <div id="guidelines-checkboxes-group">{`COMPLIANCE ${
            guidelineComplianceData.filter((item) => item.compliance_status).length
          }/${guidelineComplianceData.length}`}</div>
        </div>
        {!isComplianceCollapsed &&
          guidelineComplianceData.map((item, index) => (
            <div key={index} className="flex jcsb guidelines-compliance">
              <div style={{ width: "80%" }}>{item.guideline_description}</div>
              <div style={{ margin: "15px 30px" }}>
                {item.compliance_status ? <SuccessIcon /> : <FailureIcon />}
              </div>
            </div>
          ))}
        {showExpandCollapse &&
          (isComplianceCollapsed ? (
            <div className="guidelines-downicon">
              <DownCircleOutlined
                onClick={() => {
                  setIsComplianceCollapsed(!isComplianceCollapsed);
                }}
              />
            </div>
          ) : (
            <div className="guidelines-upicon">
              <UpCircleOutlined
                onClick={() => {
                  setIsComplianceCollapsed(!isComplianceCollapsed);
                }}
              />
            </div>
          ))}
      </div>
    );
  };

  const renderClinicalGuidelineSources = (): ReactNode => {
    return (
      <div className="flex gp-10">
        <h5>Sources: </h5>
        <div className="source-list flex gp-10">
          {!!clinicalGuidelineSources.length &&
            clinicalGuidelineSources?.map((source, i) => (
              <li className="cursor-pointer" key={i} onClick={() => handleSourceClick(source)}>
                <a>{source.category}</a>
              </li>
            ))}
        </div>
      </div>
    );
  };

  const renderCheckboxesAndComplianceData = (): ReactNode => {
    return (
      <div>
        <div className="guidelines-checkboxes">
          <div>{CHECKBOX_DATA}</div>
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.CALL_CENTER_LOGS)
            }
          >
            Call Center Logs
          </Checkbox>
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.CLAIMS_HISTORY)
            }
          >
            Claims History
          </Checkbox>
          <Checkbox
            onChange={(e) =>
              handleCheckboxChange(e.target.checked, GUIDELINES_CHECKBOXES.AUTH_HISTORY)
            }
          >
            Auth History
          </Checkbox>
        </div>
        {!!selectedCheckboxes.length && (
          <>
            <div style={{ marginBottom: "10px" }}>
              {selectedCheckboxes.map((checkboxText, index) => (
                <div key={index}>
                  <div className="guidelines-callFlags">
                    <div className="fw-700" key={index}>
                      {checkboxText.toUpperCase()}
                    </div>
                    {isSummaryLoading ? (
                      <Loading className="loading-icon" />
                    ) : (
                      <ReactTyped
                        strings={
                          claimsHistory
                            ? [claimsHistory?.replace(/&/g, "&amp;")]
                            : ["Not generated yet"]
                        }
                        typeSpeed={1}
                        showCursor={false}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            <div
              className="patientData-patientSummary guidelines-callFlags"
              style={{ height: isFaxSummaryCollapsed ? "6vh" : "auto" }}
            >
              <div className="fw-700">UPDATED PATIENT HISTORY</div> {/* Previously Fax Summary */}
              {!isFaxSummaryCollapsed &&
                (isSummaryLoading ? <Loading className="loading-icon" /> : newPatientSummaryData)}
              {showNewCompliance &&
                (isFaxSummaryCollapsed ? (
                  <div className="guidelines-downicon2">
                    <DownCircleOutlined
                      onClick={() => {
                        setIsFaxSummaryCollapsed(!isFaxSummaryCollapsed);
                      }}
                    />
                  </div>
                ) : (
                  <div className="guidelines-upicon2">
                    <UpCircleOutlined
                      onClick={() => {
                        setIsFaxSummaryCollapsed(!isFaxSummaryCollapsed);
                      }}
                    />
                  </div>
                ))}
            </div>
          </>
        )}
        {showNewCompliance && Array.isArray(newComplianceData) && (
          <div ref={rerunRef}>
            <div className="flex jcsb guidelines-guidelines">
              <div id="rerun-compliance-reference">GUIDELINE</div>
              <div>{`COMPLIANCE ${
                newComplianceData.filter((item) => item.compliance_status).length
              }/${newComplianceData.length}`}</div>
            </div>
            {newComplianceData.map((item, index) => (
              <div key={index} className="flex jcsb guidelines-compliance">
                <div style={{ width: "80%" }}>{item.guideline_description}</div>
                <div style={{ margin: "15px 30px" }}>
                  {item.compliance_status ? <SuccessIcon /> : <FailureIcon />}
                </div>
              </div>
            ))}
          </div>
        )}
        {!!Object.keys(chatSource).length && (
          <SourcePopup
            chatSource={chatSource}
            setChatSource={setChatSource}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            fileUrl={fileUrl}
          />
        )}
      </div>
    );
  };

  const renderButtonActions = (): ReactNode =>{
      return(
        <div className="flex gp">
          <Button className="outline">Copy to Clipboard</Button>
          {fileName !== "Enrolee Info.pdf" && (
            <Button className="fill" onClick={handleRerunCompliance} disabled={!isCheckboxChecked || isSummaryLoading}>
              Rerun Compliance
            </Button>
          )}
          <Button className="fill" onClick={handleGenerateAuthorization} disabled={isSummaryLoading}>
            Generate Authorization
          </Button>
        </div>
      )
  } 

  return (
    <div className="guidelines-wrapper">
      <div className="guidelines-text-container">
        <h3>Guideline Compliance</h3>
        <div className="flex jcsb guidelines-codes">
          {diagnosisCode?.diagnosisCode && (
            <div>{`Diagnosis Code - ${diagnosisCode?.diagnosisCode} ${diagnosisCode?.diagnosisDescription}`}</div>
          )}
          {procedureCode?.procedureCode && (
            <div>{`Procedure Code - ${procedureCode?.procedureCode} ${procedureCode?.procedureDescription}`}</div>
          )}
        </div>
        {renderGuidelinesCompilance()}
        {renderClinicalGuidelineSources()}
        {renderCheckboxesAndComplianceData()}
      </div>
      <div className="actions-container flex jcsb">
        <div className="patientData-footer flex">
          {/* <p>Was this helpful to you?</p>
          <LikeOutlined className="cursor-pointer flex" />{" "}
        <DislikeOutlined className="cursor-pointer flex" /> */}
          <AskAuto documentUuid={documentUUID} projectId={projectId} />
          <LikeOutlined className="cursor-pointer flex ask-auto-button" />
        </div>
        {renderButtonActions()}
      </div>
    </div>
  );
};

export default GuidelineCompliance;
