import React, { useEffect, useState } from "react";
import { AddContainerProps } from ".";
import { Button, Checkbox, Input, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { InformationIcon } from "../../../assets/icons";
import { projectService } from "../../../api/project";
import {
  DATA_EMBEDDING_MODELS_DESCRIPTION,
  STATUS_CODE,
  openNotificationWithIcon,
} from "../../../utils";
import { Dropdown } from "../../../components";
import { settingsService } from "../../../api";


export const AddProject = ({
  handleClose,
  visibility,
  handleCanFetchContainers,
}: AddContainerProps) => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [embeddingModels, setEmbeddingModels] = useState([] as IDropdownOption[]);
  const [selectedEmbeddingModel, setSelectedEmbeddingModel] = useState({
    id: -1,
    value: "Select",
  } as IDropdownOption);
  const [isDropdownsDataLoading, setIsDropdownsDataLoading] = useState(false);
  const [isPriorAuth, setIsPriorAuth] = useState(true);

  useEffect(() => {
    const dataEmbeddings = async () => {
      setIsDropdownsDataLoading(true);
      try {
        const { data: embeddingModelsData } = await settingsService.getEmbeddingModels();
        setEmbeddingModels(
          embeddingModelsData.data.map((item) => {
            return {
              id: item.id,
              value: `${item.value}`,
            };
          })
        );
        const defaultEmbeddingSetting = embeddingModelsData.data.find(
          (item) => item.isDefault === true
        );
        defaultEmbeddingSetting
          ? setSelectedEmbeddingModel({
            id: defaultEmbeddingSetting.id,
            value: defaultEmbeddingSetting.value,
          })
          : handleClose();
      } catch (err: any) {
        openNotificationWithIcon("", err.response.data.message, "error");
        if (err.response.status === STATUS_CODE.FORBIDDEN) {
          handleClose();
        }
      } finally {
        setIsDropdownsDataLoading(false);
      }
    };
    dataEmbeddings();
  }, [handleClose]);

  const handleEmbeddingModelChange = (value: string, option: IDropdownOption) => {
    setSelectedEmbeddingModel(option);
  };

  const createContainerButtonClick = async () => {
    setIsLoading(true);
    try {
      await projectService.createProject(projectName, selectedEmbeddingModel.id, projectDescription, isPriorAuth);
      openNotificationWithIcon("", "Project created successfully", "success");
      handleCanFetchContainers();
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setIsLoading(false);
      handleClose();
    }
  };

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={587}
      visible={visibility}
      footer={null}
      closable={false}
    >
      <div className="addClient-title no-bd-bt">
        <h1>{"Create Project"}</h1>
        <CloseOutlined onClick={handleClose} style={{color : "white"}}/>  
      </div>
      <div className="addClient-content">
        <p className="font-Semibold m-b">Project Name : </p>
        <Input
          className="m-b"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        <p className="font-Semibold m-b">Project Description : </p>
        <Input
          className="m-b"
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
        <div className="m-b">
          <div className="flex aic gp-10">
            <p className="font-Semibold">Embedding Model : </p>
            <div className="flex aic" title={DATA_EMBEDDING_MODELS_DESCRIPTION}>
              <InformationIcon />
            </div>
          </div>
          <p className="italic">You cannot change this setting later</p>
        </div>
        <Dropdown
          value={selectedEmbeddingModel.value}
          options={embeddingModels.map((embeddingModel) => ({
            id: embeddingModel.id,
            value: embeddingModel.value,
          }))}
          onSelect={handleEmbeddingModelChange}
          loading={isDropdownsDataLoading}
        />
        <div style={{display: 'flex', marginTop: '1em'}}>
          <p className="font-Semibold m-b">Prior Auth Project : </p>
          <Checkbox
            className="m-b"
            checked={isPriorAuth}
            defaultChecked={true}
            style={{marginLeft: '0.5em'}}
            disabled={true}
          />
        </div>
        <div className="m-t flex aic gp-10 jcsb">
          <div className="flex aic gp-10">
            <InformationIcon />
            <p>Please adjust other settings after creating the project</p>
          </div>
          <Button
            className="fill"
            onClick={createContainerButtonClick}
            disabled={projectName === "" || selectedEmbeddingModel.id === -1}
            loading={isLoading}
          >
            Create
          </Button>
        </div>
      </div>
    </Modal>
  );
};
