import React, {Dispatch,SetStateAction,useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Header } from "antd/lib/layout/layout";
import { logoutUser } from "../../../store";
import { PAGE_TITLE, PAGE_URL } from "../../../utils";
import { LogoutIcon, MenuIcon } from "../../../assets/icons";
import { useAuthUser } from "../../../hooks";
import "./app-header.scss";
import { BackArrow } from "../../../components";
import { ActiveDirectoryService } from "../../../api";

export const AppHeader = ({ pageHeader }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { firstName, lastName, roleName } = useAuthUser();

  const { pageTitle, displayBackButton, pageRoute } = useMemo(() => {
    if(pageHeader?.pageTitle) { return { pageTitle: pageHeader.pageTitle, displayBackButton: pageHeader.displayBackButton, pageRoute: pageHeader.displayBackButton }}
    const pathname = location.pathname;
    let title = PAGE_TITLE[0].title;
    let backButton = false;
    let pageURL = "";
    PAGE_TITLE.forEach((page) => {
      if (pathname.includes(page.route)) {
        title = page.title;
        backButton = page.displayBackButton;
        pageURL = page.route;
      }
    });
    return { pageTitle: title, displayBackButton: backButton, pageRoute: pageURL };
  }, [location.pathname, pageHeader?.pageTitle]);

  const logout = async (e: any) => {
    e.preventDefault();
    const { data } = await ActiveDirectoryService.activeDirectoryLogout(window.location.origin);
    if (data) {
      dispatch(logoutUser());
      navigate(data.data.logoutUri);
    }
  };

  return (
    <Header className="flex aic jcsb header">
      <div className="flex gp aic">
        {displayBackButton && <BackArrow />}
        <h1 className="header-h1 m-0">{pageTitle}</h1>
      </div>
      <div className="header-right flex gp-10 aic">
        <div className="header-content-menu pr cursor-pointer">
          <a className="header-dropdown" href="#">
            <MenuIcon />
          </a>
          <div className="header-dropdown-menu flex flex-column">
            <div className="header-content-profile-content">
              <p>
                {firstName} {lastName} <span>{roleName}</span>
              </p>
            </div>
            {/* <a
              href="#"
              className="header-dropdown-menu-item flex aic no-wrap"
              onClick={(e) => {
                e.preventDefault();
                dispatch(openModal(MODAL_TYPE.EDIT_PROFILE));
              }}
            >
              <EditProfileIcon />
              Edit Profile
            </a> */}
            <a href="#" className="header-dropdown-menu-item flex aic no-wrap" onClick={logout}>
              <LogoutIcon />
              Logout
            </a>
          </div>
        </div>
      </div>
    </Header>
  );
};
interface Props {
  pageHeader?: PageHeader;
  setPageHeader?: Dispatch<SetStateAction<PageHeader>>;
}

export interface PageHeader { pageTitle: string, displayBackButton: boolean, pageRoute: string }