import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { PdfViewer } from "../../../components";
import { openNotificationWithIcon } from "../../../utils";

type Props = {
  chatSource: ISources;
  setChatSource: (value: React.SetStateAction<ISources>) => void;
  currentPage: number;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  fileUrl: string;
};

type IOffset = {
  begin: number;
  end: number;
};

type IPages = {
  pageNo: number;
  offsets: IOffset[];
};

type ISources = {
  category: string;
  pages: IPages[];
  documentUUID: string;
  guidelines?: boolean;
};

export const SourcePopup = ({
  chatSource,
  setChatSource,
  currentPage,
  setCurrentPage,
  fileUrl,
}: Props) => {
  const downloadPDF = async (url: string, filename: string) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = filename;
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      openNotificationWithIcon("", "Downlaod Failed", "error");
    }
  };

  const handleFileDownload = async () => {
    await downloadPDF(fileUrl, `source_${new Date().getTime()}.pdf}`);
  };

  return (
    <Modal
      className="autonomizeModal modal"
      centered
      width={900}
      style={{ maxHeight: "80vh" }}
      title="Sources"
      open={!!Object.keys(chatSource).length}
      footer={null}
      onCancel={() => setChatSource({} as ISources)}
      closeIcon={<CloseOutlined />}
    >
      <div className="modal-title">
        <h1>{chatSource.documentUUID}</h1>
      </div>
      <div style={{ display: "flex", padding: "20px" }}>
        <div style={{ maxHeight: "75vh", overflow: "auto", minWidth: "50%" }}>
          <PdfViewer
            currPage={currentPage}
            setCurrPage={setCurrentPage}
            pdf={fileUrl}
            displayPagination
          />
        </div>
        <div className="flex flex-column" style={{ marginLeft: "20px" }}>
          <div className="m-b">
            <h4>Results in</h4>
            <ul className="source-list">
              {chatSource.pages.map((page, index) => (
                <li
                  className={`cursor-pointer ${page.pageNo === currentPage ? "active" : ""}`}
                  key={index}
                  onClick={() => setCurrentPage(page.pageNo)}
                >{`Page Number ${page.pageNo}`}</li>
              ))}
            </ul>
          </div>
          <div className="mt-auto">
            <Button className="fill" onClick={handleFileDownload} disabled={fileUrl === ""}>
              Download
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
