import React, { useState } from "react";
import { Button, Input, Select } from "antd";
import { useNavigate } from "react-router-dom";
import { SEARCH_KEYWORD_DELIMINATOR } from "../../../utils";
import { CloseOutlined } from "@ant-design/icons";
import { Loading } from "../../Loading";
import "./search-form.scss";

type Props = {
  keywords: string | null;
  setKeywords: (searchKeywords: string[]) => void
  setIsKeywordsEmpty: React.Dispatch<React.SetStateAction<boolean>>
};

export const SearchForm = ({ keywords, setKeywords, setIsKeywordsEmpty }: Props) => {
  const { Option } = Select;
  const [searchType, setSearchType] = useState([{ key: "All", status: "enable" }]);
  const [check, setCheck] = useState(false);
  const [selectedSearchType, setSelectedSearchType] = useState(searchType[0].key);
  const [searchKeywords, setSearchKeywords] = useState(
    keywords?.split(SEARCH_KEYWORD_DELIMINATOR) || []
  );
  const [loadingSearchParams, setLoadingSearchParams] = useState(false);

  const handleChange = (values: string[]) => {
    setCheck(true);
    if (values.length > 0) {
      const ele = values[values.length - 1];
      let insertedElement = undefined;
      insertedElement = searchKeywords.find((item) => item === ele);
      if (insertedElement) {
        const finalList = [...searchKeywords];
        const index = searchKeywords.indexOf(insertedElement);
        finalList.splice(index, 1);
        setSearchKeywords([...finalList]);
        return;
      }
    } else {
      setIsKeywordsEmpty(true)
    }
    setSearchKeywords([...values]);
  };

  return (
    <div className="search">
      <Input.Group compact>
        <Select
          mode="tags"
          placeholder="Search keyword"
          value={searchKeywords}
          onChange={handleChange}
          clearIcon={<CloseOutlined />}
          loading={loadingSearchParams}
          onDeselect={(value: string) => handleChange([value])}
          notFoundContent={loadingSearchParams && <Loading />}
        />
      </Input.Group>
      <Button
        // type="primary"
        // icon={<SearchOutlined />}
        className="outline"
        disabled={searchKeywords.length === 0}
        onClick={() => setKeywords(searchKeywords)}
      >
        Search
      </Button>
    </div>
  );
};
