import React, { useState } from "react";
import "./ask-auto-response.scss";
import { autonomizeLogo } from "../../../../assets/images";
import { CopyOutlined, LikeOutlined, RedoOutlined, DislikeOutlined} from "@ant-design/icons";
import { Tooltip, message } from "antd";
import { ReactTyped } from "react-typed";
import { useDispatch } from "react-redux";
import {openModal , setFeedbackType} from "../../../../store";
import { MODAL_TYPE } from "../../../../utils";

const AskAutoResponse = ({ response, lastResponse, redoQuestion }: Props) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [typingComplete, setTypingCompleted] = useState(false);
  const [feedbackModal, setShowFeedbackModal] = useState({
    show: false,
    type: "positive" as "positive" | "negative",
  });
  const dispatch=useDispatch();

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(response?.answer);
    messageApi.success("Copied to clipboard");
  };
  
  const handleRedoQuestion = async () => {
    await redoQuestion();
  };

  const handleReactTypedComplete = () => setTypingCompleted(true);

  const handleThumbsClick = (type: "up" | "down") => {
    // dispatch(
    //   setQuestionAnswerLogId({
    //     logId: item.logId,
    //     qaId: item.qaId,
    //   })
    // );
    dispatch(setFeedbackType(type));
    dispatch(
      openModal(MODAL_TYPE.FEEDBACK)
    );
  };

  const handleFeedback = (type: "positive" | "negative") =>
    setShowFeedbackModal({ show: true, type });

  const toggleModal = (toggleTo: boolean) =>
    setShowFeedbackModal((prev) => ({ ...prev, show: toggleTo }));

  return (
    <>
      {contextHolder}
      <div className="ask-auto-response">
        <div className="ask-auto-response-img">
          <img src={autonomizeLogo} alt="response from ask auto" />
        </div>
        <div className="response">
          <p className="response-text">
            {!lastResponse ? (
              <>{response.answer}</>
            ) : (
              <ReactTyped
                strings={[response?.answer.replace(/&/g, "&amp;")]}
                typeSpeed={1}
                showCursor={false}
                onComplete={handleReactTypedComplete}
              />
            )}
          </p>
          {typingComplete && (
            <>
              <div className="sources-container">
                <h5>Sources: </h5>
                <ul>
                  {response.sources.map(({ fileName }) => (
                    <li key={fileName}>{fileName}</li>
                  ))}
                </ul>
              </div>

              <div className="actions">
                <Tooltip title="Positive feedback">
                  <LikeOutlined onClick={() => handleThumbsClick("up")} />
                </Tooltip>
                <Tooltip title="Negative feedback">
                  <DislikeOutlined  onClick={() => handleThumbsClick("down")} />
                </Tooltip>
                <Tooltip title="Copy to clipboard">
                  <CopyOutlined onClick={handleCopyToClipboard} />
                </Tooltip>
                <Tooltip title="Redo Question">
                  <RedoOutlined onClick={handleRedoQuestion} />
                </Tooltip>
              </div>
            </>
          )}
        </div>
      </div>
    
    </>
  );
};

export default AskAutoResponse;

type Props = {
  response: {
    answer: string;
    query: string;
    qaId: string;
    sources: {
      fileName: string;
    }[];
    logId: number;
  };
  lastResponse: boolean;
  redoQuestion: () => Promise<null | undefined>;
};
