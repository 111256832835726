import React from "react";
import { PAGE_URL } from "../../../utils";
import { HomeIcon, SettingsIcon } from "../../../assets/icons";

const USER_LEFT_MENU_ITEMS: ILeftMenuItem[] = [
  { link: PAGE_URL.DASHBOARD, icon: <HomeIcon />, title: "Dashboard" },
];

const USER_LEFT_MENU_FOOTER_ITEMS: ILeftMenuItem[] = [
  { link: PAGE_URL.SETTINGS, icon: <SettingsIcon />, title: "Settings" },
];

export const getAppMenuItems = (roleCode: string) => {
  return USER_LEFT_MENU_ITEMS;
};

export const getAppFooterMenuItems = (roleCode: string) => {
  return USER_LEFT_MENU_FOOTER_ITEMS;
}
