import React, { useEffect, useState } from "react";
import { EvidencePageResponseInfo } from "../../../api";
import { getEvidenceViewerMedicalEntitiesText } from ".";

interface EvidenceViewerDefaultProps {
  pageInfo: EvidencePageResponseInfo;
  searchKeywords: string;
  selectedLegends: string[];
}

export const EvidenceViewerDefault = ({
  pageInfo,
  searchKeywords,
  selectedLegends,
}: EvidenceViewerDefaultProps) => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const newHtmlContent = getEvidenceViewerMedicalEntitiesText(
      pageInfo.content,
      pageInfo.entities.length > 0 ? pageInfo.entities : [],
      searchKeywords,
      selectedLegends
    );
    setHtmlContent(newHtmlContent);
  }, [pageInfo, searchKeywords, selectedLegends]);

  return (
    <div className="e-text">
      {/* <h2>{pageInfo.heading}</h2> */}
      <div
        className="e-paragraph"
        dangerouslySetInnerHTML={{
          __html: htmlContent,
        }}
      />
    </div>
  );
};