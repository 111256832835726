import React, { useState } from "react";
import { SettingsContainer, PageWrapper } from "../containers";

export const Settings = () => {
  const [isLoading, setIsLoading] = useState(false);

  return <PageWrapper loading={isLoading} pageContainerChildren={<SettingsContainer/>} />;
};

export default Settings;
