import { useCallback, useState } from "react";
import { STATUS_CODE, openNotificationWithIcon } from "../utils";
import { projectService } from "../api/project";
import { useAuthorization } from "./useAuthorization";

export const useProject = () => {
  const [loading, setLoading] = useState(false);
  const [deleteProjectLoading, setDeleteProjectLoading] = useState(false);
  const [projects, setProjects] = useState([] as IProject[]);
  const { setIsAuthorized } = useAuthorization();

  const fetchProjects = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await projectService.getProjects();
      setProjects(data.data);
    } catch (err: any) {
      if (err.response.status === STATUS_CODE.FORBIDDEN) {
        setIsAuthorized(false);
      } else {
        openNotificationWithIcon("", err.response.data?.message, "error");
      }
    } finally {
      setLoading(false);
    }
  }, [setIsAuthorized]);

  const deleteProject = useCallback(async (id: number) => {
    setDeleteProjectLoading(true);
    try {
      await projectService.deleteProject(id);
      openNotificationWithIcon("", "Project deleted successfully", "success");
    } catch (error: any) {
      openNotificationWithIcon("", error.response.data.message, "error");
    } finally {
      setDeleteProjectLoading(false);
    }
  }, []);

  return { fetchProjects, deleteProject, loading, deleteProjectLoading, projects };
};
