import { apiClient } from ".";

interface ISearchPagination {
  limit: number;
  offset: number;
  total: number;
}

export interface GetSearchResultsRequest {
  search: string[];
  ageGroup: string;
  gender: string;
  fileName: string;
  patient_id: string;
  type: string;
}

export interface SearchResultResponseData {
  fileName: string;
  occurences: number;
  mrn: string;
  patientId: number;
  patientName: string;
  score: string;
  fileId: string;
}

export interface SearchResultResponse {
  data: SearchResultResponseData[];
  searchData: {
    body: string[];
  };
  lastSearch: RecentAndSaveSearch;
  pagination: ISearchPagination;
}

export interface RecentAndSaveSearch {
  count: number;
  featured: boolean;
  id: number;
  createdAt: string;
  updatedAt: string;
  query: string[];
}

const getSearchResult = async (
  requestObject: Partial<GetSearchResultsRequest>,
  qs: string
) => {
  const res = await apiClient.post<SearchResultResponse>(
    `/search${qs}`,
    requestObject
  );
  return res;
};

export const searchService = {
  getSearchResult,
};
