import React, { useMemo, useState } from 'react'
import './settings-container.scss'
import { Collapse } from 'antd';
import CaseSettings from './components/case-settings/CaseSettings';

const SETTINGS_TABS = [
  { title: 'Case Settings' },
]

export const SettingsContainer = () => {
  const [menuTab, setMenuTab] = useState<string>('none');
  const menuItems = useMemo(() => [
    {
      key: 'case-settings',
      label: 'Case Settings',
      children: <CaseSettings />,
    },
  ],[])

  return (
    <div className='settings-container'>
      <Collapse accordion items={menuItems} bordered={false} />
    </div>
  )}