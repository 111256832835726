import { minidenticon } from "minidenticons";
import React, { ImgHTMLAttributes, useMemo } from "react";

interface ProjectIconProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> {
  projectName: string;
  saturation?: string | number;
  lightness?: string | number;
}

const ProjectIcon: React.FC<ProjectIconProps> = ({
  projectName,
  saturation,
  lightness,
  ...props
}) => {
  const svgText = useMemo(
    () => minidenticon(projectName, saturation, lightness),
    [projectName, saturation, lightness]
  );
  return (
    <img
      src={`data:image/svg+xml;utf8,${encodeURIComponent(svgText)}`}
      alt={projectName}
      {...props}
    />
  );
};

export default ProjectIcon;
