import { IRepsonseWrapper, apiClient } from ".";
import { SEARCH_KEYWORD_DELIMINATOR } from "../utils";

export interface EvidencePageResponseInfo {
  content: string;
  documentId: string;
  name: string;
  pageNumber: number;
  id: string;
  entities: Entity[];
}
export interface SearchOccurrence {
  pageNumber: number;
  name: string;
  documentId: string;
  inOccured: number;
  content: string;
}
export interface EvidencePageSearchOccurrencesResponse {
  occurences: number;
  occurencesList: SearchOccurrence[];
}

export interface EvidenceFileData {
  fileName: string;
  url: string;
  documentUuid: string;
}

export interface EvidenceFileResponse {
  fileData: EvidenceFileData;
}

export type Trait = {
  name: string;
  score: number;
};
export interface Entity {
  category: string;
  type: string;
  text: string;
  beginOffset: number;
  endOffset: number;
  score: number;
  traits: Trait[];
  id: number;
}

const getPageData = async (pageNum: number, documentUuid: string) => {
  const { data } = await apiClient.get<IRepsonseWrapper<EvidencePageResponseInfo>>(
    `file/evidence/${documentUuid}/${pageNum || 1}`
  );

  return data;
};

const getPDFData = async () => {
  const res = await apiClient.get<EvidencePageResponseInfo>(`extracttextpdf`);
  return res;
};

const getOccurrencesPages = async (
  fileId: string,
  searchQuery: string | null,
) => {
  const request: any = {
    searchText: searchQuery ? searchQuery.split(SEARCH_KEYWORD_DELIMINATOR) : [],
  };
  const res = await apiClient.post<IRepsonseWrapper<SearchOccurrence[]>>(
    `file/occurrence/${fileId}`,
    request
  );
  return res;
};

export const evidenceViewerService = {
  getPageData,
  getOccurrencesPages,
  getPDFData,
};
