import React, { useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { Layout } from "antd";
import { PAGE_URL } from "../../../utils";
import { autonomizeFullLogo, autonomizeLogo } from "../../../assets/images";
import { CollapseIcon } from "../../../assets/icons";
import { getAppFooterMenuItems, getAppMenuItems } from "./AppMenuItems";
import "./app-menu.scss";
import { useAuthUser } from "../../../hooks";

const { Sider } = Layout;

export const AppMenu = () => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const { roleCode } = useAuthUser();

  return (
    <Sider
      collapsible
      collapsed={collapsed}
      onCollapse={(value) => setCollapsed(value)}
      className="sidebar"
      width={205}
      trigger={
        <div className="flex gp-10 aic">
          <CollapseIcon className={collapsed ? "rotate-icon" : ""} />
        </div>
      }
    >
      <div className="logo">
        {/* <Link to={PAGE_URL.RBAC}> */}
        <img src={collapsed ? autonomizeLogo : autonomizeFullLogo} alt="autonomize-logo" />
        {/* </Link> */}
      </div>
      <div className="sidebar-nav">
        <ul>
          {getAppMenuItems(roleCode).map((item, i) => {
            return (
              <li key={`left-menu-item-${i}`}>
                <Link
                  className={`sidebar-nav-link flex aic gp ${
                    item.link.startsWith(location.pathname) ? "active" : ""
                  }`}
                  to={item.link}
                  title={collapsed ? item.title : ""}
                >
                  {item.icon}
                  {!collapsed && <span className="menu-title">{item.title}</span>}
                </Link>
              </li>
            );
          })}
        </ul>
        <div className="sidebar-footer">
        <ul>
          {getAppFooterMenuItems(roleCode).map((item, i) => {
            return (
              <li key={`left-menu-item-${i}`}>
                <Link
                  className={`sidebar-nav-link flex aic gp ${
                    item.link.startsWith(location.pathname) ? "active" : ""
                  }`}
                  to={item.link}
                  title={collapsed ? item.title : ""}
                >
                  {item.icon}
                  {!collapsed && <span className="menu-title">{item.title}</span>}
                </Link>
              </li>
            );
          })}
        </ul>
        </div>
      </div>
    </Sider>
  );
};
