import React, { useState } from "react";
import { useAuthUser } from "../../hooks";
import ProjectsList from "./ProjectDashboard";
import { Modal } from "../../containers";

const Dashboard = () => {
  const { roleCode } = useAuthUser();

  const [selectedProjectId, setSelectedProjectId] = useState(-1);
  const [selectedProjectName, setSelectedProjectName] = useState("");
  const [isPriorAuthProject, setIsPriorAuthProject] = useState(false);

  return (
    <>
      <ProjectsList
        setSelectedProjectId={setSelectedProjectId}
        setSelectedProjectName={setSelectedProjectName}
        setIsPriorAuthProject={setIsPriorAuthProject}
      />
      
    </>
  );
};

export default Dashboard;
