import { CloseOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import React, { useEffect } from "react";
import { DiagAndProcCodes } from "../../api";
import { DiagnosisProps, ProcedureProps } from "./PatientSummary";

type Props = {
  showCodePopup: boolean;
  setShowCodePopup: React.Dispatch<React.SetStateAction<boolean>>;
  diagAndProcCodes: DiagAndProcCodes[];
  setSelectedProcedureCode: React.Dispatch<React.SetStateAction<ProcedureProps>>;
  setSelectedDiagnosisCode: React.Dispatch<React.SetStateAction<DiagnosisProps>>;
  handleConfirmCode: (diagnoses?: DiagnosisProps, procedure?: ProcedureProps) => Promise<void>;
};

export const CodesPopup = ({
  showCodePopup,
  setShowCodePopup,
  diagAndProcCodes,
  setSelectedProcedureCode,
  setSelectedDiagnosisCode,
  handleConfirmCode,
}: Props) => {
  const defaultProcedureCode = diagAndProcCodes.find((item) => item.procedureCode);
  const defaultDiagnosisCode = diagAndProcCodes.find((item) => item.diagnosisCode);

  useEffect(() => {
    if (defaultProcedureCode && defaultDiagnosisCode) {
      setSelectedProcedureCode({
        procedureCode: defaultProcedureCode.procedureCode,
        procedureDescription: defaultProcedureCode.procedureDescription,
      });
      setSelectedDiagnosisCode({
        diagnosisCode: defaultDiagnosisCode.diagnosisCode,
        diagnosisDescription: defaultDiagnosisCode.diagnosisDescription,
      });
    }
  }, [defaultProcedureCode, defaultDiagnosisCode, setSelectedProcedureCode, setSelectedDiagnosisCode]);

  return (
    <Modal
      className="autonomizeModal modal"
      centered
      width={800}
      title="Confirmation"
      visible={showCodePopup}
      footer={null}
      onCancel={() => setShowCodePopup(false)}
      closeIcon={<CloseOutlined />}
    >
      <div className="modal-title">
        <h1>{"Confirmation"}</h1>
      </div>
      <div className="modal-body">
        <p>
          We have identified multiple diagnosis & procedure codes. Please select the diagnosis code
          and procedure code to check for Authorisation
        </p>
        <div className="modal-content">
          <div>
            <div className="modal-content-heading">Procedure Codes</div>
            {diagAndProcCodes
              .filter((item) => item.procedureCode)
              .map((item, index) => (
                <label className="modal-radio" key={`procedure-${index}`}>
                  <input
                    type="radio"
                    name={`procedure`}
                    value={item.procedureCode}
                    onChange={(e) =>
                      setSelectedProcedureCode({
                        procedureCode: e.target.value,
                        procedureDescription: item.procedureDescription,
                      })
                    }
                    defaultChecked={item.procedureCode === defaultProcedureCode?.procedureCode}
                  />
                  <span>{item.procedureCode}</span> - <span>{item.procedureDescription}</span>
                </label>
              ))}
          </div>
          <div>
            <div className="modal-content-heading">Diagnoses Codes</div>
            {diagAndProcCodes
              .filter((item) => item.diagnosisCode)
              .map((item, index) => (
                <label className="modal-radio" key={`diagnosis-${index}`}>
                  <input
                    type="radio"
                    name={`diagnosis`}
                    value={item.diagnosisCode}
                    onChange={(e) =>
                      setSelectedDiagnosisCode({
                        diagnosisCode: e.target.value,
                        diagnosisDescription: item.diagnosisDescription,
                      })
                    }
                    defaultChecked={item.diagnosisCode === defaultDiagnosisCode?.diagnosisCode}
                  />
                  <span>{item.diagnosisCode}</span> - <span>{item.diagnosisDescription}</span>
                </label>
              ))}
          </div>
        </div>
        <div className="modal-footer">
          <Button className="fill" onClick={() => handleConfirmCode()}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

