import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IFeedbackSlice {
  feedbackType: 1 | -1 | 0;
  logId: number;
  qaId: string;
}

export const feedbackInitialState: IFeedbackSlice = {
  feedbackType: 0,
  logId: 0,
  qaId: "",
};

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: feedbackInitialState,
  reducers: {
    setQuestionAnswerLogId: (
      state: IFeedbackSlice,
      { payload }: PayloadAction<{ logId: number; qaId: string }>
    ) => {
      const { logId, qaId } = payload;
      state.logId = logId;
      state.qaId = qaId;
    },
    setFeedbackType: (state: IFeedbackSlice, { payload }: PayloadAction<string>) => {
      if (payload === "up") {
        state.feedbackType = 1;
      } else if (payload === "down") {
        state.feedbackType = -1;
      }
    },
  },
});

export const { setFeedbackType, setQuestionAnswerLogId } = feedbackSlice.actions;
export default feedbackSlice.reducer;
