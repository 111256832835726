import React from "react";
import { Select } from "antd";

interface Props {
  defaultValue?: string;
  value?: string;
  options: IDropdownOption[];
  loading?: boolean;
  onSelect: (value: string, option: IDropdownOption) => void;
  notFoundContent?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
}

export const Dropdown = (props: Props) => {
  return (
    <Select
      className={`ai-select w-full ${props.className ? props.className : ""}`}
      getPopupContainer={(trigger) => trigger.parentNode}
      {...props}
    ></Select>
  );
};
