import { AdjudicationSummaryItem } from "../../api";

interface DocumentData {
  [key: string]: {
    [key: string]: string | number;
  };
}

interface SummaryData {
  [key: string]: string;
}
interface ProcedureRequest {
  overview: string;
  adjudication_summary: string[];
}

export const extractValues: DocumentData = {
  "Enrolee Info.pdf": {
    "Date of Birth": "02/21/2000",
    "Pharmacy Name": "Rite Aid #05319",
    "Pharmacy NPI": "1871602193",
    Prescriber: "Ruth Adeptun",
    "Prescriber NPI": "1518362862",
    "Medication and Strength": "Vraylar 1.5 mg capsule",
    "Qty/Days Supply": "30/30",
    "Diagnosis code": "F31.81",
    "Diagnosis code description": "bipolar II disorder",
  },
  "146132852320240319.pdf": {
    "Member Name": "Kelly Kurtz SR",
    DOB: "03/08/1976",
    "Molina Member ID": "110000174470",
    "Clinic Name": "Community Health Asc. of Spokane CHAS North County Clinic",
    "PCP Name": "John Kabosky",
    "PCP address": "401 Main St",
    City: "Deer Park",
    State: "WA",
    ZIP: "99006",
  },
  "188685422520240319.pdf": {
    "Member Name": "Yaretzi Cruz Jimenez",
    DOB: "12/22/2011",
    "Molina Member ID": "110001453417",
    "Clinic Name": "Quincy Community Health Center",
    "PCP Name": "India Wolfendale, PA-C",
    "PCP address": "1450 IST AVE SW",
    City: "Quincy",
    State: "WA",
    ZIP: "98848",
  },
  "156116407220240319.pdf": {
    "Member Name": "Frankie C Sweowat - Kheel",
    DOB: "8/11/23",
    "Molina Member ID": "110066481189",
    "Clinic Name": "Community Health Asc. of Spokane CHAS Perry St. Clinic",
    "PCP Name": "Deborah Wiser, MD",
    "PCP address": "817 S Perry St Suite B ",
    City: "Spokane",
    State: "WA",
    ZIP: "99202",
  },
  "165882770520240319.pdf": {
    "Member Name": "Shawna Hutchings",
    DOB: "09/21/1979",
    "Molina Member ID": "100000397767",
    "Clinic Name": "Unify Community Health",
    "PCP Name": "Natalie McPherson",
    "PCP address": "120 W Mission",
    City: "Spokane",
    State: "WA",
    ZIP: "99201",
  },
  "198485391920240319.pdf": {
    "Member Name": "Henry Berg",
    DOB: "1/17/2024",
    "Molina Member ID": "110068765125",
    "Clinic Name": "The Doctors Clinic",
    "PCP Name": "Dr. Gregory Hoisington",
    "PCP address": "1780 NW Myhre Rd #2120",
    City: "Silverdale",
    State: "WA",
    ZIP: "98383",
  },
  "Tenoudji 91213 0524.pdf": {
    Segment: "Third Party",
    "New Rx's": "439",
    "Refill Rx's": "797",
    "Total Cost": "$110075.34",
    "Total Price": "$72725.64",
  },
  "FX037042.pdf": {
    Segment: "PREPAID",
    "New Rx's": "Not applicable",
    "Refill Rx's": "Not applicable",
    "Total Cost": "507.77",
    "Total Price": "507.77",
  },
};

export const nestedValues: any = {
  "Billing Document - 8007307259.pdf": {
    "Supplier Name": "Stericycle, Inc.",
    "Supplier Address": "28883 Network Place, Chicago, IL 60673-1288",
    "Invoice Number": "8007307259",
    "Invoice Date": "06-03-2024",
    "Invoice Due Date": "07-03-2024",
    "Payment Terms": "Net due in 30 days",
    "Purchase Order (PO) Number": "8164528034",
    "Line Item Information": {
      "Item Number": "Not specified",
      Description: "REGULAR SERVICE | ON-SITE (PAPER), CONSOLE (STANDARD)",
      "Number of Items": 4,
      "Unit Price": 164.43,
      "Total Price": 164.43,
      Tax: 0.0,
      Freight: 0.0,
      "Recycling Recovery Surcharge": 22.86,
      "Fuel Surcharge": 27.95,
      "Environmental Surcharge": 6.58,
    },
    "Total Amount Due": 221.82,
    "Currency Type": "USD",
  },
  "Inv_31289_from_SILICONES_PLUS_INC._1412.pdf": {
    "Supplier Name": "Silicones Plus Inc.",
    "Supplier Address": "C/O: Amegy-Lockbox, Dept. 8147, PO BOX 650002, Dallas, TX. 75265-0002",
    "Invoice Number": "31289",
    "Invoice Date": "6/5/2024",
    "Invoice Due Date": "7/5/2024",
    "Payment Terms": "NET30",
    "Purchase Order (PO) Number": "130126",
    "Line Item Information": {
      "Item Number": "712477149097 (P/N 30-4880)",
      Description: "BOSEXIL (5KG/NET/CONTAINER)",
      "Number of Items": "1 container (5 units)",
      "Unit Price": 686.4,
      "Total Price": 3432.0,
      Tax: "Not specified",
      Freight: "Not specified (Ship via FED EX GROUND, F.O.B. ORIGIN/COL.)",
    },
    "Total Amount Due": 3432.0,
    "Currency Type": "USD",
  },
  "Inv_65375_from_Exakt_Technologies_Inc_10832.pdf": {
    "Supplier Name": "Exakt Technologies Inc.",
    "Supplier Address": "13501 Railway Dr., Oklahoma City, OK 73114",
    "Invoice Number": "65375",
    "Invoice Date": "6/6/2024",
    "Invoice Due Date": "7/6/2024",
    "Payment Terms": "Net 30",
    "Purchase Order (PO) Number": "130996",
    "Line Item Information": {
      "Item Number": "22300",
      Description: "Plastic Guide for E50 EC",
      "Number of Items": 2,
      "Unit Price": 179.2,
      "Total Price": 179.2,
      Tax: 0.0,
      Freight: "Not specified (Ship via Cust FedEx)",
    },
    "Total Amount Due": 179.2,
    "Currency Type": "USD",
  },
  "Invoice-2024-83163-00.pdf": {
    "Supplier Name": "Reliance Vitamin LLC",
    "Supplier Address": "3775 Park Avenue, Edison, NJ 08820",
    "Invoice Number": "2024-83163-00",
    "Invoice Date": "06/03/2024",
    "Invoice Due Date": "07/06/2024",
    "Payment Terms": "2% 10-Net 30",
    "Purchase Order (PO) Number": "131031",
    "Line Item Information": [
      {
        "Part Number": "1182-120",
        Description: "Prenatal Plus Tabs 120's",
        "Number of Items": 36,
        "Unit Price": 9.71,
        "Total Price": 349.65,
      },
      {
        "Part Number": "2252-60",
        Description: "Adrenal Support Vcaps 60's",
        "Number of Items": 192,
        "Unit Price": 10.84,
        "Total Price": 2080.8,
      },
      {
        "Part Number": "3251-100",
        Description: "Vitamin B6 100Mg Tabs 100's",
        "Number of Items": 24,
        "Unit Price": 3.83,
        "Total Price": 91.8,
      },
      {
        "Part Number": "4441-100",
        Description: "C 1000 With 100Mg Bio Caps 100's",
        "Number of Items": 192,
        "Unit Price": 7.28,
        "Total Price": 1396.8,
      },
      {
        "Part Number": "5341-30",
        Description: "Phosphatidyl Serine 500Mg Softgels 30's",
        "Number of Items": 180,
        "Unit Price": 9.3,
        "Total Price": 1674.0,
      },
      {
        "Part Number": "6781-60",
        Description: "Ultra Veggie Enzymes Vcaps 60's",
        "Number of Items": 60,
        "Unit Price": 8.21,
        "Total Price": 492.75,
      },
      {
        "Part Number": "6942-60",
        Description: "Nac 600 Plus Caps 60's",
        "Number of Items": 72,
        "Unit Price": 7.28,
        "Total Price": 524.16,
      },
      {
        "Part Number": "8511-100",
        Description: "Omega-3 Softgel 100's",
        "Number of Items": 36,
        "Unit Price": 4.54,
        "Total Price": 163.35,
      },
      {
        "Part Number": "8822-60",
        Description: "Brain Support Vcaps 60's",
        "Number of Items": 36,
        "Unit Price": 11.21,
        "Total Price": 403.65,
      },
      {
        "Part Number": "9111-90",
        Description: "All Season Support Tabs 90's",
        "Number of Items": 24,
        "Unit Price": 14.21,
        "Total Price": 341.1,
      },
    ],
    Tax: "Not specified",
    Freight: "Prepaid and Add (Estes)",
    "Total Amount Due": 7518.06,
    "Currency Type": "USD",
  },
}

export const extractValuesSummary: SummaryData = {
  "Enrolee Info.pdf": `General Summary:
  The faxed documents provide information about a patient and their medical history, social history, progress notes, and medication list. The documents indicate that Patient has a family history of multiple 
  sclerosis, tuberculosis, diabetes mellitus, ovarian cancer, breast cancer, and hypertension. In terms of social history, Patient does not use chewing tobacco or electronic cigarettes and is a non-smoker. The progress notes mention that Patient has been experiencing fatigue and has stopped taking the medication Rexulti due to increased fatigue. They have been trying to improve their sleep schedule but still feel fatigued. The progress notes also mention passive suicidal ideations and vitamin D insufficiency.
  
  The documents include vital signs such as blood pressure, heart rate, respiratory rate, temperature, weight, height, body mass index (BMI), and oxygen saturation levels. The vital signs indicate that Patient's blood pressure 
  was 123/77 while sitting, heart rate was 110, respiratory rate was 14, temperature was 96.2°F, weight was 208 pounds 6 ounces, height was 51 inches, BMI was 34.15, and oxygen saturation was 98%.
  
  The progress notes mention that Patient is taking Latuda 20 mg and experiencing issues with sleep quality and feeling rested upon waking up. They are considering discontinuing the medication since they do not feel any benefit from it. The progress notes also mention the addition of a new medication, Sulfur 2% Cleanser, for acne.
  
  The documents also include an assessment of Patient's medical conditions, which include bipolar 2 disorder, major depressive episode, bipolar depression, persistent depressive disorder, generalized anxiety disorder, post-traumatic stress disorder, attention and concentration deficit, and excessive daytime sleepiness. The assessment also mentions the need for medication management and a review of persistent depressive disorder.
  
  The medication list includes various medications such as venlafaxine, Sulfur 2% Cleanser, Tretinoin (Retinol A) Cream 0.025%, and Ubrelvy 100 mg oral tablet for migraine headaches.
  
  The documents also mention the need for further documentation for the authorization request, including information about the client's history of failure, contraindication, or intolerance to certain antipsychotic medications. It is also mentioned that the client should have a CrCI greater than 30 mL/min and no severe hepatic impairment.
  
  Overall, the documents provide a comprehensive overview of Patient's medical history, current medications, and ongoing concerns. The information will be used to review the authorization request and provide appropriate care and treatment for Patient.
  
  
  
  
  Summary with respect to Vraylar:
  In the provided content, there is a detailed plan for medications prescribed to a patient. The medications include Vraylar oral capsule 1.5 mg, venlafaxine ER 150 mg capsule (extended release 24 hr), and venlafaxine ER 37.5 mg capsule (extended release 24 hr). The instructions for Vraylar are to take 1 capsule (1.5 mg) orally once daily in the afternoon. The prescription was given on 12/14/2022. The instructions for venlafaxine ER 150 mg capsule are to take 1 capsule (150 mg) orally once daily in the afternoon with food. This prescription was refilled on 12/14/2022. The venlafaxine ER 37.5 mg capsule was replaced with Vraylar 1.5 mg capsule once daily in the afternoon with venlafaxine ER 187.5 mg for bipolar depression.
  
  The future plan of care discussed with the patient includes the possibility of switching to Caplyta or lithium carbonate if she is unable to tolerate Vraylar. The phone number provided for contact is (509) 339-2894, and the fax number is (509) 886-7484. The prescription for Vraylar is for 30 capsules with 0 refills.
  
  In addition to the medication plan, there is a discussion about the insurance coverage and formulary. Caplyta is mentioned as a non-preferred drug that requires prior authorization, while Vraylar is a preferred drug that also requires prior authorization. Latuda is mentioned as another atypical antipsychotic medication that may require prior authorization or step therapy before Vraylar. The next follow-up visit with the NP is scheduled in 4 weeks, with the option for an earlier visit if necessary. The prescriptions for Vraylar and venlafaxine ER were sent to Rite Aid #05319 in Richland, WA, as per patient's request.
  
  The progress note also mentions the discontinuation of Latuda due to unsatisfactory treatment response for bipolar depression. It was replaced with Vraylar 1.5 mg capsule once daily. Patient's risk for self-harm or harm to others is assessed as low, although her chronic risk is moderately elevated due to static and dynamic risk factors. She is aware of emergency procedures and can access emergency care if necessary.
  
  The progress note also includes information about other medications prescribed to Patient, such as Sulfur 2% Cleanser and Tretinoin (Retinol A) Cream 0.025% for acne, and Ubrelvy 100 mg oral tablet for migraine headaches.    
  
  The prior authorization request for Vraylar is mentioned, and more information is needed to review the request. The dosage strengths of Vraylar are listed as 20 mg, 40 mg, 60 mg, 80 mg, and 120 mg tablets, and it must be taken with at least 350 calories, usually with dinner or supper.
  
  Overall, the detailed summary provides a comprehensive overview of the medication plan for Patient, including the prescribed medications, dosage instructions, refills, and future plans. It also includes information about insurance coverage, prior authorization requirements, and the patient's risk assessment.
  `,
  "146132852320240319.pdf": `- Date and time of transmission: Tuesday, March 19, 2024, 2:28:31 PM PDT
- Instructions for PCP change effective the first day of the following month, with new ID cards to be received within 7-10 business days after processing
- Member’s Full Name: Jane Doe SR
- Phone: (530)201-0203
- Molina Healthcare ID #: 111111111111
- Date of Birth: 01/01/1999
- Question asking if any of the listed members have seen the new provider within 12 months: No
- Member or Parent/Guardian Full Name and Signature: Jane Doe Sr
- New Provider Information: Clinic Name: CHAS North County Clinic, PCP Address: 111 E23 St, City: Deer Park, State: TX, ZIP: 19823
- Member Information: Name: Jane Doe SR, Phone: (530)201-0203, Date of Birth: 01/01/1999, Molina Healthcare ID #: 111111111111`,

  "188685422520240319.pdf": `- Date and time of transmission: Tuesday, March 19, 2024, 1:25:28 PM
- Instructions for PCP change effective the first day of the following month, with new ID cards to be received within 7-10 business days after processing
- Member’s Full Name: John Doe
- Phone: 111 222 3333
- Molina Healthcare ID #: 111111000000
- Date of Birth: 1/1/2010
- Question asking if any of the listed members have seen the new provider within 12 months: Yes
- Member or Parent/Guardian Full Name and Signature: John Doe Sr
- New Provider Information:
  - Clinic Name: Quincy Community Health Center
  - PCP Address: 1450 1st Ave SW
  - City: Quincy
- Member Information:
  - Name: John Doe
  - State: WA
  - Zip: 98848
  - Date of Birth: 1/1/2010
  - Molina Healthcare ID #: 111111000000`,

  "156116407220240319.pdf": `- Date and time of transmission: Tuesday, March 19, 2024, 12:31:43 PM PDT
- Instructions for PCP change effective the first day of the following month, with new ID cards to be received within 7-10 business days after processing
- Member’s Full Name: John Jr
- Phone: Not provided
- Molina Healthcare ID #: 999888225167
- Date of Birth: 1/1/1999
- Member or Parent/Guardian Full Name: John Doe Sr
- Member or Parent/Guardian Signature: Not provided
- New Provider Information:
  - Clinic Name: CHAS Perry St. Clinic
  - PCP Address: 817 S Perry St Suite B
  - City: Spokane
- Member Information:
  - Name: John Jr
  - State: WA
  - Zip: 99202
  - Date of Birth: 1/1/1999
  - Molina Healthcare ID #: 999888225167
- Additional Member Information for other members listed: Not provided`,

  "165882770520240319.pdf": `- Date and time of transmission: Tuesday, March 19, 2024, 1:25:28 PM
- Instructions for PCP change effective the first day of the following month, with new ID cards to be received within 7-10 business days after processing
- Member’s Full Name: Jane Doe
- Phone: 212.121.3215
- Molina Healthcare ID #: 111156783215
- Date of Birth: 01/01/1971
- New Provider Information:
  - Clinic Name: Unify Community Health
  - PCP Address: 120 W Mission
  - City: Spokane
- Member Information:
  - Name: Jane Doe
  - State: WA
  - Zip: 99201
  - Date of Birth: 01/01/1971
  - Molina Healthcare ID #: 111156783215
- Member or Parent/Guardian Full Name and Signature: Jane Doe
- Additional Member Information: None provided`,

  "198485391920240319.pdf": `- Date and time of transmission: Tuesday, March 19, 2024, 11:47:17 AM
- Instructions for PCP change effective the first day of the following month, with new ID cards to be received within 7-10 business days after processing
- Member’s Full Name: John Doe II
- Phone: 456-5768-1234
- Molina Healthcare ID #: 121245659875
- Date of Birth: 01-02-2040
- Clinic Name: The Doctors Clinic
- PCP Name: Dr. Gregory Hoisington
- PCP Address: 1780 NW Myhre Rd #2120, Silverdale
- City: Silverdale
- State: WA
- Zip: 98383
- Member’s Parent/Guardian Full Name: John Doe I
- Member’s Parent/Guardian Signature: (not provided)
- Additional Member Information: None provided`,
};

export const guidelineCompliance: AdjudicationSummaryItem[] = [
  {
    compliance_status: true,
    guideline_description:
      "Cariprazine may be covered when the client is 18 years of age or older.",
    observation:
      "The patient, is 22 years old, which is older than the required age of 18 as per the guideline.",
  },
  {
    compliance_status: true,
    guideline_description:
      "Clients 17 years of age or younger require a second opinion review with the agency-designated mental health specialist from the Second Opinion Network (SON).",
    observation: "As the patient is 22 years old, this guideline is not applicable.",
  },
  {
    compliance_status: false,
    guideline_description:
      "Client meets ONE of the following: a. History of either failure after 4 weeks, contraindication, or intolerance to THREE of the following oral atypical antipsychotics: i. Lurasidone ii. Olanzapine+Fluoxetine (Combination product or individual products taken concurrently) iii. Quetiapine b. Documentation that client has been taking cariprazine and is stabilized at the requested dose.",
    observation:
      "The patient's medical history does not provide any information about a history of failure, contraindication, or intolerance to Lurasidone, Olanzapine+Fluoxetine, or Quetiapine. Additionally, there is no documentation indicating that the patient has been taking cariprazine and is stabilized at the requested dose.",
  },
  {
    compliance_status: false,
    guideline_description: "Client has a CrCl >30mL/min.",
    observation:
      "The patient's medical history does not provide any information about the patient's CrCl levels.",
  },
  {
    compliance_status: false,
    guideline_description: "Client has no severe hepatic impairment (Child Pugh Score ≥10).",
    observation:
      "The patient's medical history does not provide any information about the patient's hepatic function or Child Pugh Score.",
  },
];

export const authorization: ProcedureRequest = {
  adjudication_summary: [
    `Guideline Description: Cariprazine may be covered when the client is 18 years of age or older.
Observation: The patient, is 22 years old, which is older than the required age of 18 as per the guideline.
Compliance Status: COMPLIANT`,

    `Guideline Description: Clients 17 years of age or younger require a second opinion review with the agency-designated mental health specialist from the Second Opinion Network (SON).
Observation: As the patient is 22 years old, this guideline is not applicable.
Compliance Status: COMPLIANT`,

    `Guideline Description: Client meets ONE of the following: a. History of either failure after 4 weeks, contraindication, or intolerance to THREE of the following oral atypical antipsychotics: i. Lurasidone ii. Olanzapine+Fluoxetine (Combination product or individual products taken concurrently) iii. Quetiapine b. Documentation that client has been taking cariprazine and is stabilized at the requested dose.
Observation: The patient's medical history does not provide any information about a history of failure, contraindication, or intolerance to Lurasidone, Olanzapine+Fluoxetine, or Quetiapine. Additionally, there is no documentation indicating that the patient has been taking cariprazine and is stabilized at the requested dose.
Compliance Status: NON COMPLIANT`,

    `Guideline Description: Client has a CrCl >30mL/min.
Observation: The patient's medical history does not provide any information about the patient's CrCl levels.
Compliance Status: NON COMPLIANT`,

    `Guideline Description: Client has no severe hepatic impairment (Child Pugh Score ≥10).
Observation: The patient's medical history does not provide any information about the patient's hepatic function or Child Pugh Score.
Compliance Status: NON COMPLIANT`,
  ],
  overview: `Recap: The patient's compliance status for procedure authorization is non-compliant based on explicit evidence. The patient is compliant with the age-related guidelines but is non-compliant with the guidelines related to the history of antipsychotic use, CrCl levels, and hepatic function.
  PATIENT CONDITIONS ARE COMPLIANT FOR PROCEDURE: NO
  EXPLANATION FOR NON COMPLIANCE: The patient's medical history does not provide information about a history of failure, contraindication, or intolerance to the specified antipsychotics, nor does it provide information about the patient's CrCl levels or hepatic function. For compliance authorization, additional patient evidence or medical history is needed regarding these specific guideline requirements.
  `,
};
