import React, { useEffect, useState } from "react";
import "./prior-authorization.scss";
import { Button } from "antd";
import { priorAuthService } from "../../api";
import { openNotificationWithIcon } from "../../utils";
import { useNavigate } from "react-router-dom";
import { DiagnosisProps, ProcedureProps } from "../patient-summary";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import { LikeOutlined } from "@ant-design/icons";
import { authorization } from "../case-details-container/constants";

export const PriorAuthorization = ({
  pageData,
  projectId,
  diagnosisCode,
  procedureCode,
  selectedCheckboxes,
  fileName,
}: Props) => {
  const [authContent, setAuthContent] = useState<string>("");
  const [activeTab, setActiveTab] = useState<"ams" | "qco">("ams");
  const [summary, setSummary] = useState({ ams: "", qco: "" });
  const [loading, setLoading] = useState({ summary: { ams: false, qco: false } });
  const [overview, setOverview] = useState("" as string);
  const [adjudicationSummary, setAdjudicationSummary] = useState([] as string[]);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);

  const navigate = useNavigate();

  const getAuthorizationData = async () => {
    try {
      setIsSummaryLoading(true);
      const { data } = await priorAuthService.generateAuthorizationOverview(
        pageData.documentUUID,
        diagnosisCode,
        procedureCode,
        selectedCheckboxes
      );
      if (fileName === "Enrolee Info.pdf") {
        setOverview(authorization.overview);
        setAdjudicationSummary(authorization.adjudication_summary);
      } else {
        setOverview(data.data.overview || "");
        setAdjudicationSummary(data.data.adjudication_summary || "");
      }
      setIsSummaryLoading(false);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    getAuthorizationData();
  }, []);

  const handleGenerateSummary = async () => {
    try {
      setLoading((prev) => ({ ...prev, summary: { ...prev.summary, [activeTab]: true } }));
      const payload = {
        q: "",
        metadataFilter: 0,
        containerId: projectId,
        qaId: String(crypto.randomUUID()),
        documentUuid: pageData.documentUUID,
        docVariant: "prior-auth",
      };

      if (activeTab === "ams") {
        payload.q = "Suggest adjacent medical services needed";
      } else {
        payload.q = '"Summarize quality care opportunities"';
      }

      const {
        data: {
          data: { answer },
        },
      } = await priorAuthService.getSummary(payload);
      setSummary((prev) => ({ ...prev, [activeTab]: answer }));
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    } finally {
      setLoading((prev) => ({ ...prev, summary: { ...prev.summary, [activeTab]: false } }));
    }
  };

  const getSubmitReview = async (status: string) => {
    try {
      await priorAuthService.submitReview({ status }, pageData.documentUUID);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleApprove = async () => {
    await getSubmitReview("APPROVED");
    navigate("/dashboard");
  };
  const handleReqForMoreInfo = async () => {
    await getSubmitReview("REQUESTED_MORE_INFO");
    navigate("/dashboard");
  };
  const handleForwardForReview = async () => {
    await getSubmitReview("FORWARDED_FOR_REVIEW");
    navigate("/dashboard");
  };

  return (
    <div className="authorization-container">
      <div className="authorization-container-content">
        <section className="display-auth">
          <h3>Authorization</h3>
          {isSummaryLoading ? (
            <Loading />
          ) : (
            <div className="display-auth-content">
              <div>{overview}</div>
              <h3>Detailed Compliance Report</h3>
              {adjudicationSummary.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          )}
        </section>
        {/* <section className="summary-actions-container">
          <div className="actions">
            <span
              className={`actions-single ${activeTab === "ams" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("ams")}
            >
              Adjacent Medical Services
            </span>
            <span
              className={`actions-single ${activeTab === "qco" ? "active-tab" : ""}`}
              onClick={() => setActiveTab("qco")}
            >
              Quality Care Opportunities
            </span>
          </div>
          <div className="generator-container white-box-bg">
            <div className="generator-content">
              <div>{summary[activeTab]}</div>
              {!summary[activeTab] && (
                <Button
                  className="outline"
                  onClick={handleGenerateSummary}
                  disabled={loading.summary[activeTab]}
                >
                  {loading.summary[activeTab] ? "Generating..." : "Generate"}
                </Button>
              )}
            </div>
          </div>
        </section> */}
      </div>
      <div className="case-actions">
        <div className="actions">
          <AskAuto projectId={projectId} documentUuid={pageData.documentUUID} />
          <LikeOutlined className="cursor-pointer flex ask-auto-button" />
        </div>

        <div className="actions">
          <Button className="outline">Export Summary as</Button>
          <Button className="fill" onClick={handleApprove}>
            Approve
          </Button>
          <Button className="fill" onClick={handleReqForMoreInfo}>
            Request for more information
          </Button>
          <Button className="fill" onClick={handleForwardForReview}>
            Forward for review
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PriorAuthorization;

type Props = {
  pageData: {
    key: React.Key;
    fileFolder: string;
    createdOn: string;
    updatedOn: string;
    status: string;
    action: string;
    isFolder: boolean;
    name: string;
    documentUUID: string;
  };
  projectId: number;
  procedureCode?: ProcedureProps;
  diagnosisCode?: DiagnosisProps;
  selectedCheckboxes?: string[];
  fileName: string;
};
