import { IRepsonseWrapper, apiClient } from ".";
const getProjects = async () => {
  const res = await apiClient.get<IRepsonseWrapper<IProject[]>>(`/project/overview?isPriorAuth=true`);
  return res;
};

const getProject = async (id: number) => {
  const res = await apiClient.get<IRepsonseWrapper<ProjectDetailResponse>>(`project/${id}`);
  return res;
};

const deleteProject = async (id: number) => {
  const res = await apiClient.delete<IRepsonseWrapper<IProject>>(`/project/${id}`);
  return res;
};

const createProject = async (
  name: string,
  dataEmbeddingId: number,
  projectDescription: string,
  isPriorAuth: boolean,
  excludeBolbStorage?: boolean
) => {
  const qs = excludeBolbStorage ? `?exclude_blob_storage=${excludeBolbStorage}` : "";
  const res = await apiClient.post<IRepsonseWrapper<IProject>>(`project${qs}`, {
    name,
    dataEmbeddingId,
    description: projectDescription,
    isPriorAuth
  });
  return res;
};

export const projectService = {
  getProjects,
  getProject,
  deleteProject,
  createProject
};
