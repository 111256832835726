import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProject } from "../../hooks";
import {
  RootState,
  openModal,
  updateDeleteConfirmation,
} from "../../store";
import { MODAL_TYPE, PAGE_URL, getLocalDateTimeStringFromISO } from "../../utils";
import { Card, Table } from "antd";
import { Loading } from "../../components";
import "./project-container.scss";
import ProjectIcon from "../../components/project-icon/ProjectIcon";
import { updateCanFetchContainers } from "../../store/file-folder-upload/slice";
import {plusIcon}  from "../../assets/images";

interface Props {
  setSelectedProjectId: React.Dispatch<React.SetStateAction<number>>;
  setSelectedProjectName: React.Dispatch<React.SetStateAction<string>>;
  setIsPriorAuthProject: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProjectsTable = ({
  setSelectedProjectId,
  setSelectedProjectName,
  setIsPriorAuthProject,
}: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { deleteProject, fetchProjects, loading, projects } = useProject();
  const isDeleteConfirmed = useSelector((state: RootState) => state.ui.isDeleteConfirmed);
  const { canFetchContainers } = useSelector((state: RootState) => state.fileFolderUpload);
  const [projectToBeDeleted, setProjectToBeDeleted] = useState(-1);
  const sortedProjects = projects.sort((a, b) => b.id - a.id);
  
  useEffect(() => {
    dispatch(updateCanFetchContainers(true));
  }, [dispatch]);

  useEffect(() => {
    if (canFetchContainers) {
      fetchProjects();
      dispatch(updateCanFetchContainers(false));
    }
  }, [canFetchContainers, dispatch, fetchProjects]);

  useEffect(() => {
    const deleteProj = async () => {
      await deleteProject(projectToBeDeleted);
      dispatch(updateDeleteConfirmation(false));
      dispatch(updateCanFetchContainers(true));
    };
    if (isDeleteConfirmed) {
      deleteProj();
    }
  }, [deleteProject, dispatch, isDeleteConfirmed, projectToBeDeleted]);

  //Function taking project info and based on that rendering a card
  const renderProjectCard = (project: IProject) => (
    <Card
      className="cursor-pointer p-20"
      onClick={() => {
        setSelectedProjectId(project.id);
        setSelectedProjectName(project.name);
        navigate(`/projects/${project.id}`, {
          state: {
            selectedProjectId: project.id,
            selectedProjectName: project.name,
            isPriorAuthProject: project.isPriorAuth ? true : false,
          },
        });
        if (project.isPriorAuth) setIsPriorAuthProject(true);
        else setIsPriorAuthProject(false);
      }}
      style={{ width: "60vw", marginLeft: "auto", marginRight: "auto" }}
    >
      <div style={{ display: "flex" }}>
        <ProjectIcon
          projectName={project.name}
          width="50"
          height="50"
          style={{ margin: "auto", marginRight: "2em", marginLeft: "0" }}
        ></ProjectIcon>
        <div>
          <p className="project-card-title m-b">{project.name}</p>
          <p style={{ fontSize: "12px" }}>{project.description}</p>
        </div>
      </div>
    </Card>
  );

  //Function to render create project card
  const renderCreateProjectCard = () => (
      <div key={`create-project`}>
        <Card
          className="cursor-pointer p-20"
          onClick={() => {   
            console.log("Create Project clicked");
            dispatch(openModal(MODAL_TYPE.ADD_PROJECT));
          }}
          style={{ width: "60vw", marginLeft: "auto", marginRight: "auto" }}
        >
          <div style={{ display: "flex" }}>
            <img
              className="plus-icon"
              src={plusIcon}
              style={{ width: "40px", margin: "auto", marginRight: "2em", marginLeft: "0.5em" }}
            />
            <div>
              <p className="project-card-title m-b">Create Project</p>
              <p style={{ fontSize: "12px" }}>Create new project</p>
            </div>
          </div>
        </Card>
      </div>
  );
  
  return (
    <div style={{ height: "100vh" }}>
      {loading ? (
        <Loading />
      ) : (
        <div className="project-card-grid">
         {/* <h2
          style={{
          marginLeft: "1em",
          color: "#1e293b",
          fontFamily: '"Font-Semibold", Arial, Sans-Serif',
          fontSize: "18px",
          }}
        >
        Projects
        </h2> */}
        {renderCreateProjectCard()}
        {sortedProjects.map((project, index) => (
            <div key={`${index}`}>
             {renderProjectCard(project)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectsTable