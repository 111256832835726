import React from "react";
import { MODAL_TYPE } from "../../utils";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import { openModal } from "../../store";
import { withAuthorization } from "../../components";
import ProjectsTable from "./ProjectsTable";

const AuthorizedProjectsTable = withAuthorization(ProjectsTable);
interface Props {
    setSelectedProjectId: React.Dispatch<React.SetStateAction<number>>;
    setSelectedProjectName: React.Dispatch<React.SetStateAction<string>>;
    setIsPriorAuthProject: React.Dispatch<React.SetStateAction<boolean>>;
}
const ProjectsList = (
    {
        setSelectedProjectId,
        setSelectedProjectName,
        setIsPriorAuthProject
    }: Props
) => {
  const dispatch = useDispatch();

  const handleCreateContainerClick = () => {
    dispatch(openModal(MODAL_TYPE.ADD_PROJECT));
  };

  return (
    <div style={{backgroundColor: '#ffffff', overflow: 'auto'}}>
      <br/>
      <AuthorizedProjectsTable setSelectedProjectId={setSelectedProjectId} setSelectedProjectName={setSelectedProjectName} setIsPriorAuthProject={setIsPriorAuthProject}/>
    </div>
  );
};

export default ProjectsList;