import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { MODAL_TYPE } from "../../utils";
import AddProject from "./add-project";
import "./modal.scss";
import ExpandPdf from "./expand-pdf";
import  FeedbackForm  from "./feedback";
import EvidenceViewer from "./evidence-viewer";

export const Modal = () => {
  const { modal } = useSelector((state: RootState) => state.ui);

  return (
    <>
      {modal[MODAL_TYPE.ADD_PROJECT] && <AddProject />}
      {modal[MODAL_TYPE.EXPAND_PDF] && <ExpandPdf />}
      {modal[MODAL_TYPE.FEEDBACK] && <FeedbackForm/>}
      {modal[MODAL_TYPE.EVIDENCE_VIEWER] && <EvidenceViewer />}
    </>
  );
};
