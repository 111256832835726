import { IRepsonseWrapper, apiClient } from ".";

const getCallFlagDetails = async (callFlag?: string) => {
  const { data } = await apiClient.get<IRepsonseWrapper<CallFlagDataResponse>>(`/setting/case/call-flag-details${callFlag ? `?type=${callFlag}` : ''}`);
  return data;
}

const addCallFlagDetails = async (newCallFlagData: CallFlagData) => {
  const { data: { data } } = await apiClient.post<IRepsonseWrapper<SubmitCallFlagData>>(`/setting/case/call-flag-details`, newCallFlagData);
  return data;
};

const getEmbeddingModels = async () => {
  const res = await apiClient.get<IRepsonseWrapper<DocumentIngestionSetting[]>>(
    "/setting/data-embedding"
  );
  return res;
};

export const settingsService = {
    addCallFlagDetails, 
    getCallFlagDetails, 
    getEmbeddingModels,
};


/*  --------  INTERFACES  --------  */
export interface CallFlagData {
    auth?: string;
    call?: string;
    claim?: string;
}
export interface SubmitCallFlagData {
  data: CallFlagData;
  message: string;
}
export interface CallFlagDataResponse {
    AUTH: string;
    CALL: string;
    CLAIM: string;
}