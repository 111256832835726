export const PAGE_URL = {
  LOGIN: "/",
  RESET_PASSWORD: "/reset-password",
  RESET_PASSWORD_SUCCESS: "/reset-password-success",
  CREATE_PASSWORD: "/create-password",
  CREATE_PASSWORD_SUCCESS: "/create-password-success",
  DASHBOARD: "/dashboard",
  REDIRECTION: "/redirect",
  CASE_DETAILS: "/case-details",
  SETTINGS: "/settings",
  PROJECTS: "/projects",
};

export const PAGE_TITLE = [
  { route: PAGE_URL.DASHBOARD, title: "Prior Authorization AI Copilot", displayBackButton: false },
  {
    route: PAGE_URL.PROJECTS,
    title: "Projects",
    displayBackButton: true,
  },
  {
    route: PAGE_URL.REDIRECTION,
    title: "Authentication",
    displayBackButton: false,
  },
  {
    route: PAGE_URL.CASE_DETAILS,
    title: "Case Details",
    displayBackButton: true,
  },
  {
    route: PAGE_URL.SETTINGS,
    title: "Settings",
    displayBackButton: true,
  },
];
