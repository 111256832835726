import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { CaseDetailsContainer, PageWrapper } from "../containers";
import { IOutletContext } from "../navigation/PrivateLayout";
import { PAGE_URL} from "../utils/constants";
import { useDispatch } from "react-redux";
import { resetDocument, setDocumentUuid } from "../store";

export const CaseDetails = () => {
  const location = useLocation();
  const { rowData, projectId } = location.state;
  const [isLoading, setIsLoading] = useState(false);
  const { documentUUID } = useParams();
  const navigate = useNavigate();
  const { setPageHeader, resetPageHeader } = useOutletContext<IOutletContext>();
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (!documentUUID) {
      navigate(`/dashboard`);
    }
    setPageHeader({
      pageRoute: `${PAGE_URL.CASE_DETAILS}/{documentUuid}`,
      pageTitle: rowData?.name,
      displayBackButton: true,
    });

    dispatch(setDocumentUuid(documentUUID!));

    return () => {
      dispatch(resetDocument());
      resetPageHeader();
    };
  }, [documentUUID]);

  return (
    <PageWrapper
      containerClassName="no-padding"
      pageContainerChildren={
        <CaseDetailsContainer
          pageData={rowData}
          documentUUID={documentUUID!}
          projectId={projectId}
        />
      }
    />
  );
};

export default CaseDetails;
