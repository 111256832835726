import React, { useEffect, useState } from "react";
import "./patient-summary.scss";
import { Button, Modal } from "antd";
import {
  CloseOutlined,
  DislikeOutlined,
  DownCircleOutlined,
  LikeOutlined,
  UpCircleOutlined,
} from "@ant-design/icons";
import { DiagAndProcCodes, patientSummaryService } from "../../api";
import { ReactTyped } from "react-typed";
import { MODAL_TYPE, openNotificationWithIcon } from "../../utils";
import { Loading } from "../../components";
import AskAuto from "../../components/ask-auto/AskAuto";
import { CodesPopup } from "./CodesPopup";
import { useDispatch } from "react-redux";
import { openModal, setFeedbackType } from "../../store";

export type ProcedureProps = {
  procedureCode?: string;
  procedureDescription?: string;
};

export type DiagnosisProps = {
  diagnosisCode?: string;
  diagnosisDescription?: string;
};

type Props = {
  enableNextTab: (procedureCode: ProcedureProps, diagnosisCode: DiagnosisProps) => void;
  documentUUID: string;
  projectId: number;
  pdf: string;
  fileName: string;
};

interface Procedure {
  procedureCode: string;
  dateOfServiceStart: string;
  dateOfServiceEnd: string;
  procedureDescription: string;
}

interface Diagnosis {
  diagnosisCode: string;
  diagnosisQualifier: string;
  diagnosisDescription: string;
}

interface PatientData {
  acuity: string;
  admissionDateTime: string;
  authorizationID: string;
  dateOfBirth: string;
  dischargeDateTime: string;
  memberID: string;
  memberName: string;
  placeOfService: string;
  requestingProviderName: string;
  requestingProviderFaxNo: string;
  requestingProviderNPI: string;
  servicingProviderName: string;
  servicingProviderNPI: string;
  typeOfService: string;
  state: string;
  procedures: Procedure[];
  diagnoses: Diagnosis[];
  [key: string]: string | any;
}

const patientDataKeys = Object.keys({
  acuity: "",
  admissionDateTime: "",
  authorizationID: "",
  dateOfBirth: "",
  dischargeDateTime: "",
  memberID: "",
  memberName: "",
  placeOfService: "",
  requestingProviderName: "",
  requestingProviderFaxNo: "",
  requestingProviderNPI: "",
  servicingProviderName: "",
  servicingProviderNPI: "",
  typeOfService: "",
  state: "",
  procedures: [],
  diagnoses: [],
});

export const PatientSummary = ({
  enableNextTab,
  documentUUID,
  projectId,
  pdf,
  fileName,
}: Props) => {
  const [showPatientSummary, setShowPatientSummary] = useState(false);
  const [expandPatientData, setExpandPatientData] = useState(false);
  const [showCodePopup, setShowCodePopup] = useState(false);
  const [selectedProcedureCode, setSelectedProcedureCode] = useState<ProcedureProps>({
    procedureCode: "",
    procedureDescription: "",
  });
  const [selectedDiagnosisCode, setSelectedDiagnosisCode] = useState<DiagnosisProps>({
    diagnosisCode: "",
    diagnosisDescription: "",
  });
  const [patientSummary, setPatientSummary] = useState("");
  const [caseInfo, setCaseInfo] = useState({} as PatientData);
  const [diagAndProcCodes, setDiagAndProcCodes] = useState([] as DiagAndProcCodes[]);
  const [timeToProcess, setTimeToProcess] = useState<number>(0);
  const [extractedFieldCount, setExtractedFieldCount] = useState<number>(0);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const dispatch = useDispatch();

  const getCaseInfo = async () => {
    try {
      const { data } = await patientSummaryService.getCaseInfo(documentUUID);
      setCaseInfo(data.data.KeyValueOutput);
      setDiagAndProcCodes(data.data.diagAndProcCodes);
      setTimeToProcess(data.data.timeToProcess);
      setExtractedFieldCount(data.data.extractedFieldCount);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  useEffect(() => {
    getCaseInfo();
  }, []);

  const handleClose = () => {
    setShowCodePopup(false);
  };

  const handleConfirmCode = async (diagnoses?: DiagnosisProps, procedure?: ProcedureProps) => {
    try {
      handleClose();
      setIsSummaryLoading(true);
      const { data } = await patientSummaryService.generatePatientSummary(
        documentUUID,
        diagnoses ? diagnoses : selectedDiagnosisCode,
        procedure ? procedure : selectedProcedureCode
      );
      setPatientSummary(data.data.summary || data.data.message || "Summary not found");
      setShowPatientSummary(true);
      setExpandPatientData(false);
      setIsSummaryLoading(false);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleGeneratePatientSummary = () => {
    if (diagAndProcCodes.length > 2) {
      setShowCodePopup(true);
    } else if (diagAndProcCodes.length === 2) {
      const splitCodes = diagAndProcCodes.reduce(
        (acc: any, code) => {
          if (code.diagnosisCode) {
            acc.diagnosisCodes.push({
              diagnosisCode: code.diagnosisCode,
              diagnosisDescription: code.diagnosisDescription,
            });
          } else if (code.procedureCode) {
            acc.procedureCodes.push({
              procedureCode: code.procedureCode,
              procedureDescription: code.procedureDescription,
            });
          }
          return acc;
        },
        { diagnosisCodes: [], procedureCodes: [] }
      );
      const { diagnosisCodes, procedureCodes } = splitCodes;
      setSelectedProcedureCode({
        procedureCode: procedureCodes[0].procedureCode,
        procedureDescription: procedureCodes[0].procedureDescription,
      });
      setSelectedDiagnosisCode({
        diagnosisCode: diagnosisCodes[0].diagnosisCode,
        diagnosisDescription: diagnosisCodes[0].diagnosisDescription,
      });
      handleConfirmCode(
        {
          diagnosisCode: diagnosisCodes[0].diagnosisCode,
          diagnosisDescription: diagnosisCodes[0].diagnosisDescription,
        },
        {
          procedureCode: procedureCodes[0].procedureCode,
          procedureDescription: procedureCodes[0].procedureDescription,
        }
      );
    }
  };

  const handleGenerateCompliance = () => {
    enableNextTab(selectedProcedureCode, selectedDiagnosisCode);
  };

  const formatKey = (key: string) => {
    const words = key.split(/(?=[A-Z])/);
    const formattedKey = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
    return formattedKey;
  };

  const downloadPDF = async () => {
    try {
      const response = await fetch(pdf);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = fileName;
      link.click();
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      openNotificationWithIcon("", "Downlaod Failed", "error");
    }
  };

  const handleThumbsClick = (type: "up" | "down") => {
    // dispatch(
    //   setQuestionAnswerLogId({
    //     logId: item.logId,
    //     qaId: item.qaId,
    //   })
    // );
    dispatch(setFeedbackType(type));
    dispatch(openModal(MODAL_TYPE.FEEDBACK));
  };

  return (
    <>
      <div className="patientData">
        <div className="patientData-info">
          {`Time to process: ${(
            timeToProcess / 1000
          ).toFixed()} secs  |  Extracted fields count: ${extractedFieldCount}`}
        </div>
        <div
          className={`patientData-patientInfo  ${
            patientSummary
              ? `patientData-patientInfo-${expandPatientData ? "expanded" : "short"}`
              : "show-full"
          }`}
        >
          {Object.keys(caseInfo).length > 0 && (
            <div className="single-info grid grid-cols-2">
              <div className="patientData-info">{formatKey(Object.keys(caseInfo)[0])}</div>
              <div className="fz-16 p-block">{caseInfo[`${Object.keys(caseInfo)[0]}`]}</div>
            </div>
          )}

          {expandPatientData && (
            <>
              {caseInfo &&
                Object.entries(caseInfo)
                  .filter(([key]) => patientDataKeys.includes(key))
                  .map(([key, value], index) => {
                    if (key !== Object.keys(caseInfo)[0] && value) {
                      return (
                        <div key={index}>
                          {key !== "procedures" && key !== "diagnoses" && (
                            <div className="single-info grid grid-cols-2">
                              <div className="patientData-info">{formatKey(key)}</div>
                              <div className="fz-16 p-block">{value}</div>
                            </div>
                          )}
                          {key === "procedures" && (
                            <>
                              {value.map((procedure: Procedure, procedureIndex: number) => (
                                <div
                                  key={`procedure-${procedureIndex}`}
                                  className="single-info grid grid-cols-2"
                                >
                                  <div className="patientData-info">
                                    Procedure {procedureIndex + 1}
                                  </div>
                                  <div className="fz-16 p-block">
                                    <div>{`Procedure Code: ${procedure.procedureCode}`}</div>
                                    <div>{`Date of Service Start: ${procedure.dateOfServiceStart}`}</div>
                                    <div>{`Date of Service End: ${procedure.dateOfServiceEnd}`}</div>
                                    <div>{`Procedure Description: ${procedure.procedureDescription}`}</div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                          {key === "diagnoses" && (
                            <>
                              {value.map((diagnosis: Diagnosis, diagnosisIndex: number) => (
                                <div
                                  key={`diagnosis-${diagnosisIndex}`}
                                  className="single-info grid grid-cols-2"
                                >
                                  <div className="patientData-info">
                                    Diagnosis {diagnosisIndex + 1}
                                  </div>
                                  <div className="fz-16 p-block">
                                    <div>{`Diagnosis Code: ${diagnosis.diagnosisCode}`}</div>
                                    <div>{`Diagnosis Qualifier: ${diagnosis.diagnosisQualifier}`}</div>
                                    <div>{`Diagnosis Description: ${diagnosis.diagnosisDescription}`}</div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      );
                    } else {
                      return null; // Skip rendering if value is empty
                    }
                  })}
            </>
          )}
        </div>

        {!expandPatientData ? (
          <div className="patientData-downicon">
            <DownCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        ) : (
          <div className="patientData-upicon">
            <UpCircleOutlined
              onClick={() => {
                setExpandPatientData(!expandPatientData);
              }}
            />
          </div>
        )}
        {showPatientSummary ? (
          isSummaryLoading ? (
            <Loading />
          ) : (
            <div className="patientData-patientSummary">
              <h2 className="patientData-info">Patient Summary</h2>
              <ReactTyped
                strings={[patientSummary.replace(/&/g, "&amp;")]}
                typeSpeed={1}
                showCursor={false}
                style={{ fontSize: "16px" }}
              />
            </div>
          )
        ) : (
          <div className="patientData-actions">
            <div className="actions">
              <AskAuto projectId={projectId} documentUuid={documentUUID} />
              <LikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("up")}
              />
              <DislikeOutlined
                className="cursor-pointer flex ask-auto-button"
                onClick={() => handleThumbsClick("down")}
              />
            </div>
            <Button
              className="fill"
              style={{ float: "right" }}
              onClick={handleGeneratePatientSummary}
            >
              Generate Patient Summary
            </Button>
          </div>
        )}

        {showPatientSummary && (
          <div className="patientData-footer">
            <div className="actions">
              <AskAuto buttonClassName="action" documentUuid={documentUUID} projectId={projectId} />
              <LikeOutlined className="cursor-pointer flex action" />
            </div>
            <div className="flex gp">
              <Button className="outline">Export Summary as</Button>
              <Button className="fill" onClick={handleGenerateCompliance}>
                Generate Guideline Compliance
              </Button>
            </div>
          </div>
        )}
      </div>
      {showCodePopup && (
        <CodesPopup
          {...{
            showCodePopup,
            setShowCodePopup,
            diagAndProcCodes,
            setSelectedProcedureCode,
            setSelectedDiagnosisCode,
            handleConfirmCode,
          }}
        />
      )}
    </>
  );
};

export default PatientSummary;
