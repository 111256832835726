import React, { useEffect, useState } from "react";
import Table from "antd/lib/table";
import {
  DOCUMENT_STATUS,
  REVIEW_STATUS,
  getLocalDateTimeStringFromISO,
  openNotificationWithIcon,
} from "../../utils";
import { useFetchDocuments } from "../../hooks";
import { CloseOutlined, DeleteOutlined, EyeOutlined, MoreOutlined } from "@ant-design/icons";
import { PdfViewer } from "../pdf-viewer/PdfViewer";
import { projectService, uploadFileFolderService } from "../../api";
import "./uploadFiles.scss";
import { useNavigate } from "react-router-dom";
import { AppPagination } from "../app-pagination/AppPagination";
import { Dropdown, Menu } from "antd";

interface Props {
  projectId?: number;
  loading?: boolean;
}

interface IUploadedDocumentsDataType {
  key: React.Key;
  fileFolder: string;
  createdOn: string;
  updatedOn: string;
  status: string;
  action: string;
  isFolder: boolean;
  documentUUID: string;
  totalPages: number;
  reviewStatus: string;
}

const getClass = (value: string) => {
  switch (value) {
    case DOCUMENT_STATUS.PROCESSING:
      return "#ff9800";
    case DOCUMENT_STATUS.READY_WITH_SUMMARY:
    case DOCUMENT_STATUS.READY:
      return "#44c658";
    case DOCUMENT_STATUS.FAILED:
      return "#f44336";
  }
};

const getReviewClass = (value: string) => {
  switch (value) {
    case REVIEW_STATUS.NOT_REVIEWED:
      return "#ff9800";
    case REVIEW_STATUS.APPROVED:
    case REVIEW_STATUS.REQUESTED_FOR_MORE_INFO:
    case REVIEW_STATUS.FORWARDED_FOR_REVIEW:
      return "#44c658";
  }
};

export const UploadedFilesFolders = (props: Props) => {
  const { projectId, loading } = props;
  const navigate = useNavigate();
  const {
    fetchDocuments,
    documents,
    setIsDocumentsLoading,
    isDocumentsLoading,
    paginationObj,
    totalDocuments,
  } = useFetchDocuments();
  const { onPaginationChange, page, pageSize, pageSizeOptions } = paginationObj;

  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [fileUrl, setFileUrl] = useState("");

  const handlePreviewOnClick = async (
    path: string,
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      const { data } = await uploadFileFolderService.getSignedUrlByFilepath(path, projectId);
      setFileUrl(data.data.signedUrl);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const handleRowClick = (record: IUploadedDocumentsDataType) => {
    if (record.status === DOCUMENT_STATUS.READY_WITH_SUMMARY) {
      navigate(`/case-details/${record.documentUUID}`, {
        state: { rowData: record, projectId: projectId },
      });
    } else if (record.status === DOCUMENT_STATUS.READY) {
      openNotificationWithIcon("", "Please wait till the summary is generated", "info");
    } else {
      openNotificationWithIcon(
        "",
        "The File is still processing or failed. Please try with completed files",
        "info"
      );
    }
  };

  const getReviewText = (text: string) => {
    switch (text) {
      case REVIEW_STATUS.NOT_REVIEWED:
        return "Not Reviewed";
      case REVIEW_STATUS.APPROVED:
        return "Approved";
      case REVIEW_STATUS.REQUESTED_FOR_MORE_INFO:
        return "Requested more information";
      case REVIEW_STATUS.FORWARDED_FOR_REVIEW:
        return "Forwarded for review";
    }
  };

  const handleDeleteFile = async (id: string) => {
    try {
      const { data } = await uploadFileFolderService.deleteFile(id);
      fetchDocuments(projectId);
    } catch (err: any) {
      openNotificationWithIcon("", err.response.data.message, "error");
    }
  };

  const getColumns: any = () => [
    {
      title: "Case ID",
      key: "documentUUID",
      dataIndex: "documentUUID",
      render: (_: string, record: any) => (
        <span className="table-doc-id" onClick={() => handleRowClick(record)}>
          {record.documentUUID}
        </span>
      ),
    },
    {
      title: "No. of Pages",
      key: "totalPages",
      dataIndex: "totalPages",
      className: "dashboard-table-page-count",
    },
    {
      title: "Received Date",
      className: "dashboard-table-mid-size",
      key: "createdOn",
      dataIndex: "createdOn",
    },
    {
      title: "Processing Status",
      className: "dashboard-table-status",
      key: "status",
      dataIndex: "status",
      render: (text: any, value: any) => (
        <>
          {value.status && (
            <div>
              <div className="status" style={{ color: getClass(value.status) }}>
                <p style={{ color: getClass(value.status) }}>{value.status.replace(/_/g, " ")}</p>{" "}
              </div>
            </div>
          )}
        </>
      ),
    },
    {
      title: "Review Status",
      dataIndex: "reviewStatus",
      className: "dashboard-table-mid-size",
      key: "reviewStatus",
      render: (text: any, value: any) => (
        <>
          {value.status && (
            <div
              className="status"
              style={{ color: getReviewClass(value.reviewStatus || "NOT_REVIEWED") }}
            >
              <p style={{ color: getReviewClass(value.reviewStatus || "NOT_REVIEWED") }}>
                {getReviewText(value.reviewStatus || "NOT_REVIEWED")}
              </p>{" "}
            </div>
          )}
        </>
      ),
    },
    {
      title: "Preview",
      className: "actions-tile",
      render: (text: any, value: any) => (
        <div
          className="dashboard-table-preview"
          onClick={(e) => handlePreviewOnClick(value.name, e)}
        >
          <EyeOutlined className="dashboard-table-preview-icon" title="Preview document" />
        </div>
      ),
    },
    {
      title: "Actions",
      className: "actions-tile",
      render: (value: any, record: any) => (
        <Dropdown
          overlay={
            <Menu
              onClick={({ key }) => {
                if (key === "delete") handleDeleteFile(record.documentUUID);
              }}
            >
              <Menu.Item key="delete">
                <DeleteOutlined /> Delete
              </Menu.Item>
            </Menu>
          }
          trigger={["click"]}
        >
          <div className="dashboard-table-actions">
            <MoreOutlined className="dashboard-table-actions-icon" title="More options" />
          </div>
        </Dropdown>
      ),
      align: "center",
    },
  ];

  useEffect(() => {
    if (projectId && !loading) {
      fetchDocuments(projectId);
    }
  }, [projectId, loading, page, pageSize]);

  const filteredData = documents.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const data: IUploadedDocumentsDataType[] = filteredData
    .filter((item) => item.name !== "")
    .map((item, index) => ({
      action: "",
      isFolder: item.isFolder,
      updatedOn: getLocalDateTimeStringFromISO(item.updatedAt),
      createdOn: getLocalDateTimeStringFromISO(item.createdAt),
      fileFolder: item.name,
      key: index,
      status: item.status.toUpperCase(),
      name: item.name,
      documentUUID: item.documentUUID,
      totalPages: item.totalPages,
      reviewStatus: item.reviewStatus,
    }));

  return (
    <div className="dashboard-table">
      {/* <h3>Recent Cases</h3> */}
      {/* <Input
        placeholder="Search file name"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      /> */}
      <AppPagination
        className="tr m-b paginationDiv"
        {...{
          showSizeChanger: true,
          current: page,
          total: totalDocuments,
          pageSize,
          pageSizeOptions,
          onChange: onPaginationChange,
        }}
      />
      <div style={{ display: "flex" }}>
        <Table
          rowClassName={"cursor-pointer"}
          columns={getColumns()}
          pagination={false}
          dataSource={data}
          loading={isDocumentsLoading}
        />
        {fileUrl && (
          <div className="pdf-viewer">
            <PdfViewer
              pageInfo={{ height: 623, width: 400 }}
              pdf={fileUrl}
              displayPagination
              setCurrPage={setCurrentPage}
              currPage={currentPage}
            />
            <CloseOutlined className="pdf-viewer-closeicon" onClick={() => setFileUrl("")} />
          </div>
        )}
      </div>
    </div>
  );
};
