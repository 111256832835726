import React from "react";
import { IconsProps } from "./Icons";

export const SuccessIcon = ({ className, fill, width, height, viewBox }: IconsProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="12" r="10" fill="#4CAF50" />
      <path d="M7.5 12L10 14.5L16.5 8" stroke="#ffffff" />
    </svg>
  );
};
