import React from "react";
import { Routes, useLocation, Route } from "react-router-dom";
import { GuestLayout } from "./GuestLayout";
import { PrivateLayout } from "./PrivateLayout";
import { PAGE_URL } from "../utils";
import * as Pages from "../pages";
import { RedirectLayout } from "./RedirectLayout";

export const AppRoutes = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route element={<PrivateLayout />}>
        <Route path={PAGE_URL.DASHBOARD} element={<Pages.Dashboard />} />
        <Route path={`${PAGE_URL.PROJECTS}/:projectId`} element={<Pages.Project />} />
        <Route path={`${PAGE_URL.CASE_DETAILS}/:documentUUID`} element={<Pages.CaseDetails />} />
        <Route path={PAGE_URL.SETTINGS} element={<Pages.Settings />} />
      </Route>
      <Route element={<GuestLayout />}>
        <Route path={PAGE_URL.LOGIN} element={<Pages.Login />} />
      </Route>
      <Route element={<RedirectLayout />}>
        <Route path={PAGE_URL.REDIRECTION} element={<Pages.Redirect />} />
      </Route>
    </Routes>
  );
};
