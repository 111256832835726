import { Modal } from "antd";
import React, { useState } from "react";
import { ExpandPdfProps } from ".";
import { CloseOutlined } from "@ant-design/icons";
import { PdfViewer } from "../../../components";
import "./expand-pdf.scss";

export const ExpandPdf = (props: ExpandPdfProps) => {
  const { fileName: signedUrl, handleClose, visibility } = props;
  const [currentPage, setCurrentPage] = useState(1);

  return (
    <Modal
      className="autonomizeModal"
      centered
      width={1100}
      open={visibility}
      footer={null}
      title={"Source"}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <div className="flex addClient-title">
        <h1 className="mb-0">Source</h1>
      </div>
      <div className="data-source-pdf-container">
        <PdfViewer
          pdf={signedUrl}
          displayPagination
          setCurrPage={setCurrentPage}
          pageInfo={{ scale: 2 }}
          noExpand
          currPage={currentPage}
          className="data-source-pdf"
        />
      </div>
    </Modal>
  );
};
