import React from "react";
import { Pagination, PaginationProps } from "antd";
import { PaginationLeftArrowIcon, PaginationRightArrowIcon } from "../../assets/icons";
import "./app-pagination.scss";
import "../../assets/sass/_pagination.scss";

const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <span className="pagination-icon">
        <PaginationLeftArrowIcon />
      </span>
    );
  }
  if (type === "next") {
    return (
      <span className="pagination-icon">
        <PaginationRightArrowIcon />
      </span>
    );
  }
  return originalElement;
};

export const AppPagination = (props: PaginationProps) => {
  return (
    <Pagination className="pagination" itemRender={itemRender} showSizeChanger={false} {...props} />
  );
};
